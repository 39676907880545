import { useEffect, useState } from 'react'
import './App.css';
import { HashRouter as Router, Routes, Route } from "react-router-dom";
import IntroAnimation from './components/IntroAnimation';
import AnimationController from './components/AnimationController';

function App() {

  const [animationState, setAnimationState] = useState(0)
  const [mode, setMode] = useState('starting')

  function onStorageUpdate(e){
    const { key, newValue } = e;
    if (key === "animationState") {
      setAnimationState(parseInt(newValue));
    }
    if (key === "animationMode") {
      setMode(newValue)
    }
  }

  useEffect(() => {
    setAnimationState(parseInt(localStorage.getItem("animationState")) || 0)
    setMode(localStorage.getItem("animationState") || "starting")
    window.addEventListener("storage", onStorageUpdate);
    return () => {
      window.removeEventListener("storage", onStorageUpdate);
    };
  }, []);

  return (
    <Router>
      <Routes>
        <Route path="" element={<IntroAnimation animationState={animationState} mode={mode}/>}/>
        <Route path="controller" element={<AnimationController setAnimationState={setAnimationState} setMode={setMode} />}/>
      </Routes>
    </Router>
  );
}

export default App;
