import { useEffect, useRef } from "react"

export function ClosestDot(){
    const canvasRef = useRef(null)
    const pixel = 4
    const maxDistance = 180
    const n = 100
    const fps = 25
    const width = window.innerWidth
    const height = window.innerHeight

    function randomBetween(min, max) {
        return (Math.floor(Math.random() * Math.pow(10, Math.log10(max) + 1)) % (max - min + 1)) + min
    }

    class Dot{
        constructor() {
            this.x = randomBetween(0, width)
            this.y = randomBetween(0, height)
            this.velX = Math.random() * (randomBetween(0, 1) ? -pixel : pixel)
            this.velY = Math.random() * (randomBetween(0, 1) ? -pixel : pixel)
        }

        update() {
            this.x += this.velX
            this.y += this.velY
            if(this.x < 0 || this.x > width){
                this.x = this.x < 0 ? 0 : width
                this.velX *= -1
            }
            if(this.y < 0 || this.y > height){
                this.y = this.y < 0 ? 0 : height
                this.velY *= -1
            }
        } 

        render(ctx) {
            ctx.fillRect(this.x, this.y, pixel, pixel)
        }
    }

    function euclidean(x1, y1, x2, y2){
        return Math.sqrt(Math.pow(x1 - x2, 2) + Math.pow(y1 - y2, 2))
    }

    function updateCanvas(arr, context){
        context.clearRect(0, 0, width, height)
        
        for(let i = 0; i < n; i++)
            arr[i].update()

        for(let i = 0; i < n; i++){
            let curr = arr[i]
            curr.render(context)
            for(let y = i + 1; y < n; y++){
                let evaluate = arr[y]
                let eucli = euclidean(curr.x, curr.y, evaluate.x, evaluate.y)
                if (eucli > maxDistance) continue
                context.lineWidth = (maxDistance - eucli) / maxDistance * pixel

                context.beginPath()
                context.moveTo(curr.x + pixel / 2, curr.y + pixel / 2)
                context.lineTo(evaluate.x + pixel / 2, evaluate.y + pixel / 2)
                
                context.stroke()
            }
        }
        setTimeout(() => {updateCanvas(arr, context)}, 1000 / fps)
    }

    useEffect(() => {
        const canvas = canvasRef.current
        const context = canvas.getContext('2d')
        context.fillStyle = '#fff'
        context.strokeStyle = '#fff'

        // context.fillRect(10,10,n,n);

        // context.beginPath()
        // context.moveTo(10, 10);
        // context.lineTo(20, 20);
        
        // context.stroke();

        const arr = []
        for(let i = 0; i < n; i++)
            arr.push(new Dot())

        updateCanvas(arr, context)
    // eslint-disable-next-line
    }, [])

    return (
        <div className="h-full w-full bg-gradient-to-br from-secondary to-primary">
            <canvas ref={canvasRef} width={width} height={height}></canvas>
        </div>
    )
}