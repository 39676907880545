import { useEffect, useState } from "react"
import Gear from '../assets/Asset2.png'
import OuterBar from '../assets/Asset1.png'
import Logo from '../assets/Asset3.png'
import { ClosestDot } from "./ClosestDot"

export default function IntroAnimation({ animationState, mode }){

    const textStroke = {textShadow: '-2px -2px 0 #fff, 2px -2px 0 #fff, -2px 2px 0 #fff, 2px 2px 0 #fff'}
    const n = 5
    const [isOpen, setOpen] = useState((1 << n) - 1)
    const [endingText, setEndingText] = useState({idx: 0, length: 0})
    const doorAnimation = 'transition-[transform] duration-500 ease-in-out'
    const borderStyles = [
      'border-t-[50vh] border-l-[50vw] border-r-[50vw] border-t-primary border-l-transparent border-r-transparent',
      'border-b-[50vh] border-l-[50vw] border-l-transparent border-b-primary',
      'border-b-[50vh] border-r-[50vw] border-r-transparent border-b-primary'
    ]
    const endingTextArr = [
      "STREAM ENDING",
      "はいしん",
      "配信おわります",
      "THANK YOU",
      "ありがとうございます"
    ]
    const group1 = 1
    const group2 = 3
    const group3 = 4
    const group4 = 5
    const group5 = 2
  
    useEffect(() => {
      setTimeout(() => {
        if(!animationState && (isOpen << 1) + 1 > (1 << n) - 1) return
        if(isEnding()){
          if(isOpen === (1 << 4) - 1) setEndingText({idx: 0, length: 1})
          if(isOpen === 0) setEndingText({idx: 0, length: 0})
        }

        console.log(animationState, isOpen)
        if(animationState) setOpen(isOpen >> 1)
        else setOpen((isOpen << 1) + 1)

      }, 500)
      // eslint-disable-next-line
    }, [isOpen, animationState])

    useEffect(() => {
      setTimeout(() => {
        if(isOpen < (1 << 4) - 1 || animationState) return
        if(endingText.length > endingTextArr[endingText.idx].length){
           
          if(endingText.idx === 1)
            setEndingText({length: 2, idx: 2})
          else setTimeout(() => {
            setEndingText({
              length: 0, 
              idx: endingText.idx + 1 >= endingTextArr.length ? 0 : endingText.idx + 1
            })
          }, 3000)
        }
        else setEndingText({length: endingText.length + 1, idx: endingText.idx})
      }, 150)
      // eslint-disable-next-line
    }, [endingText])

    function isSequence(seq){
      return isOpen & (1 << (seq - 1))
    }

    function isEnding() {
      return mode === 'ending'
    }
  
    function getBackgroundColor(){
      if(animationState)
        return isSequence(group1) ? 'bg-primary' : ''
      return isSequence(group2) ? 'bg-primary' : ''
    }
  
    function getTranslate(idx){
      const translateArr = ['-translate-y-full', 'translate-x-full', '-translate-x-full']
      return isSequence(group1) ? '' : translateArr[idx] 
    }
  
    function outerBar(rotate){
      return (
        <div className={`absolute top-0 left-0 w-full h-full flex justify-center items-center ${rotate}`}>
          <img src={OuterBar} className='animate-wiggle transition-[transform] w-9/12' alt="" />
        </div>
      )
    }

    function renderClosestDot() {
      return isSequence(group1) ? <ClosestDot /> : ""
    }

    return (
        <div className="relative w-screen h-screen overflow-hidden font-extrabold text-6xl ">
            <div className={`absolute top-0 left-0 w-1/2 h-full ${getTranslate(2)} ${doorAnimation}`}>
              <div className={`h-1/2 ${getBackgroundColor()} ${borderStyles[2]}`}></div>
              <div className='h-1/2 bg-primary'></div>
            </div>
            <div className={`absolute top-0 right-0 w-1/2 h-full ${getTranslate(1)} ${doorAnimation}`}>
              <div className={`h-1/2 ${getBackgroundColor()} ${borderStyles[1]}`}></div>
              <div className='h-1/2 bg-primary'></div>
            </div>
            <div className={`absolute top-0 left-0 ${getTranslate(0)} ${doorAnimation} ${borderStyles[0]}`}></div>
            <div className={`canvas ${isSequence(group5) ? '' : 'opacity-0'} duration-300 transition-opacity absolute h-full w-full`}>{renderClosestDot()}</div>
            <div className='absolute h-full w-full flex justify-center items-center'>
              <div className="relative flex justify-center items-center">
                <div className={`${isSequence(group2) ? '' : 'scale-150 opacity-0'} duration-300 animate-rotate transition-[transform,opacity,width] relative 
                ${isEnding() && isSequence(group4) ? 'w-52' : 'w-96'}`}>
                  <img src={Gear} className='w-full h-full' alt="" />
                  {outerBar('rotate-0')}
                  {outerBar('rotate-[120deg]')}
                  {outerBar('rotate-[240deg]')}
                </div>
                <img src={Logo} className={`${isSequence(group3) ? '' : 'scale-150 opacity-0'} duration-300 transition-[transform,opacity,height] absolute 
                  ${isEnding() && isSequence(group4) ? 'h-52' : 'h-96'}`} alt="" />
              </div>
              <div className={`relative ${isEnding() && isSequence(group4) ? 'w-[51.25rem]' : 'w-0'} duration-300 transition-[width] overflow-hidden flex items-center`}>
                <span className="opacity-0 py-10">A</span>
                <div className="absolute border-l-8 border-white py-5 ml-36 w-[51.25rem]">
                  <div className="relative flex">
                    <span className="opacity-0 py-2">A</span>
                    <span className="p-2 text-primary" style={textStroke}>{endingTextArr[endingText.idx].slice(0, endingText.length)}</span>
                    <div className={`w-2 bg-white ${endingText.length > endingTextArr[endingText.idx].length ? 'animate-blink' : ''}`}></div>
                  </div>
                </div>
              </div>
            </div>
            <div className={`${isSequence(group4) ? '' : 'translate-x-[300px] opacity-0'}
            ${!isEnding() ? 'duration-300 transition-[transform,opacity]' : 'opacity-0'}
             absolute right-0 bottom-0 mb-14 mr-10 flex`}>
              {/* <div className='relative w-16'><div class="absolute bottom-0 rounded-full w-16 h-16 animate-bounce bg-secondary"></div></div> */}
              <div className={`ml-5 flex flex-col items-center text-secondary`}>
                <div className="underline" style={textStroke}>STREAM LOADING</div>
                <div className="mt-4" style={textStroke}>配信まもなく始まる</div>
              </div>
            </div>
        </div>
    )
}