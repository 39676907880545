export default function AnimationController({ setAnimationState, setMode }) {

    function handleChange(flag) {
        setAnimationState(flag)
        localStorage.setItem("animationState", flag)
    }

    function handleMode(str) {
        setMode(str)
        localStorage.setItem("animationMode", str)
    }

    return (
        <div className="flex flex-col items-center w-screen h-screen">
            <div className="h-1/2 w-full">
                <button className="w-1/2 h-full p-10 text-4xl bg-primary text-white border-8" 
                    onClick={() => handleChange(1)}>OPEN</button>
                <button className="w-1/2 h-full p-10 text-4xl bg-primary text-white border-8" 
                    onClick={() => handleChange(0)}>CLOSE</button>
            </div>
            <div className="h-1/2 w-full">
                <button className="w-1/2 h-full p-10 text-4xl bg-primary text-white border-8" 
                    onClick={() => handleMode('starting')}>OPENING</button>
                <button className="w-1/2 h-full p-10 text-4xl bg-primary text-white border-8" 
                    onClick={() => handleMode('ending')}>ENDING</button>
            </div>
        </div>
    )
}